import { produce } from "immer";
import _ from "lodash";
import React, { Component } from "react";
import { Button, Checkbox, Icon, Image, ImageGroup, Label, Message, Modal, Popup, Segment } from "semantic-ui-react";
import { Utils } from "../../copied/Utils";
import { MarkdownExt } from "../MarkdownExt";
import { TestClassDescriptor, TestFunctionDescriptor } from "../TestsAreDemoMaster";
import { RecordedTest } from "../recordedTest/RecordedTest";
import { RecordedTestModal } from "../recordedTest/RecordedTestModal";
import { FeaturebookCommonProps } from "./Featurebook";
import { fetchJsonCredentials } from "./functions";

// @ts-ignore
import d from "../../common-lib-and-node/common.mjs";
const { RECORDED_TESTS_DIR, RECORDED_TESTS_INDEX, SMALL_SUFFIX } = (d as CommonLibAndNodeDefaultExport);
import { CommonLibAndNodeDefaultExport } from "../../common-lib-and-node/common-types";

interface Props extends FeaturebookCommonProps {

    includeCredentialsInJsonRequest?: boolean;
    /**
     * Optional to allow reusing from RecordedTestModal.
     */
    testClassDescriptor?: TestClassDescriptor;
    testFunctionDescriptor: TestFunctionDescriptor;
    recordedTestsUrl?: string;
    expanded?: boolean;
    onToggleExpanded?: () => void;
    showThumbnails?: boolean;
    onToggleShowThumbnails?: () => void;
}

interface State {
    recordedTest?: RecordedTest;
    modalOpenAtScreenshotWithIndex?: number;
}

export class TestFunctionComponent extends Component<Props, State> {

    state: State = {};

    constructor(props: Props) {
        super(props);
        // this.componentDidUpdate();
    }

    async componentDidUpdate(prevProps: Readonly<Props>) {
        if (!this.props.recordedTestsUrl) {
            return;
        }
        if (prevProps.expanded !== this.props.expanded) {
            this.setState({ recordedTest: undefined });
        }
        if (this.props.expanded && (prevProps.expanded !== this.props.expanded
            || prevProps.recordedTestsUrl !== this.props.recordedTestsUrl
            || prevProps.testClassDescriptor !== this.props.testClassDescriptor
            || !_.isEqual(prevProps.testFunctionDescriptor, this.props.testFunctionDescriptor))) { // deep equals, because they are recalculated on each render

            const recordedTest = await fetchJsonCredentials<RecordedTest>(this.getUrl() + "/recordedTest.json", this.props.includeCredentialsInJsonRequest);
            this.setState({ recordedTest });
        }
    }

    getUrl() {
        const props = this.props;
        const recordedTestsUrl = props.recordedTestsUrl || RECORDED_TESTS_DIR + RECORDED_TESTS_INDEX;
        return Utils.substringBefore(recordedTestsUrl!, "/", true) + "/" + props.testClassDescriptor?.name + "/" + props.testFunctionDescriptor.functionName + "/";
    }

    render() {
        return <TestFunctionComponentInternal {...this.props} recordedTest={this.state.recordedTest} parent={this} />;
    }
}

const CommentComponent = (props: { comment: string }) => {
    return <MarkdownExt>{props.comment}</MarkdownExt>
}

interface PropsInternal extends Props {
    recordedTest?: RecordedTest;
    parent?: TestFunctionComponent;
    additionalForSegment2?: JSX.Element;
}

export function TestFunctionComponentInternal(properties: PropsInternal) {
    let { master, testClassDescriptor, testsToRun, testFunctionDescriptor, recordedTest, parent, ...props } = properties;
    const images: string[] = [];
    const comments: string[] = [];
    // matches in "@img my-img", "my-img"
    const regexImg = /@img\s*(\S*)/g;
    if (parent && testFunctionDescriptor.comments) {
        testFunctionDescriptor.comments.forEach(c => {
            const matches = c.matchAll(regexImg);
            for (const match of matches) {
                images.push(match[1]);
            }
            comments.push(c.replace(regexImg, ""));
        })
    }

    return <div className="TestFunctionComponent">
        <Message className="TestFunctionComponent_segment1" attached>
            {parent && !props.recordedTestsUrl && <Checkbox label="&nbsp;" checked={testsToRun?.[testClassDescriptor!.name]?.[testFunctionDescriptor.functionName] === true} onChange={() => {
                if (!testsToRun) {
                    testsToRun = {};
                }
                master?.updateTestsToRun(produce(testsToRun, draft => {
                    let clazz = draft[testClassDescriptor!.name];
                    if (!clazz) {
                        clazz = draft[testClassDescriptor!.name] = {};
                    }
                    if (clazz[testFunctionDescriptor.functionName]) {
                        delete clazz[testFunctionDescriptor.functionName];
                    } else {
                        clazz[testFunctionDescriptor.functionName] = true;
                    }
                }));
            }} />}
            <h3>
                {props.recordedTestsUrl && <Icon name={(props.expanded ? "minus" : "plus") + " square outline" as any} link onClick={(e: any) => props.onToggleExpanded?.()} />}
                {testFunctionDescriptor.scenario}
            </h3> &nbsp;
            <Label horizontal>{testFunctionDescriptor.functionName}</Label>
        </Message>
        {recordedTest && <Message className="TestFunctionComponent_segment2" attached={parent ? true : "bottom"} warning>
            <div>
                <Label horizontal circular color="orange" size="small" content="R" />
                Recorded test {parent && <a href="#" onClick={e => { parent!.setState({ modalOpenAtScreenshotWithIndex: 0 }); e.preventDefault(); }}>(open)</a>}&nbsp;
                {props.showThumbnails && <br />}
                <Label horizontal basic color={recordedTest.error ? "red" : "green"}>{recordedTest.error ? "FAILED" : "PASSED"}</Label>
                <Label horizontal basic>Duration: {recordedTest.duration} ms</Label>
                {props.additionalForSegment2}
            </div>

            {parent && props.showThumbnails && recordedTest.slides.map((slide, i) => <Image key={slide.screenshot} as="a" href=""
                src={parent!.getUrl() + slide.screenshot + ".png"} size="tiny" bordered rounded
                onClick={(e: React.MouseEvent) => { parent!.setState({ modalOpenAtScreenshotWithIndex: i }); e.preventDefault(); }}
            />)}
            {parent && <>
                <Button icon="images outline" onClick={props.onToggleShowThumbnails} content={props.showThumbnails ? "Hide thumbnails" : "Show thumbnails"} />
                <Button icon="film" onClick={e => parent!.setState({ modalOpenAtScreenshotWithIndex: 0 })} content="Open" />
            </>}
        </Message>}
        {images.length !== 0 && <Segment className="TestFunctionComponent_segment2" attached>
            {images.map(i => <Modal key={i} closeIcon trigger={<Image bordered src={parent!.getUrl() + i + SMALL_SUFFIX + ".png"}
                rounded as="a" href="" onClick={(e: React.MouseEvent) => e.preventDefault()} />}
                content={<Image style={{ margin: "auto" }} key={i} bordered rounded src={parent!.getUrl() + i + ".png"} />}
                actions={["Close"]}>
            </Modal>
            )}
            &nbsp;<i>(click on image for full size image)</i>
        </Segment>}
        {comments.map((comment, i) => (
            <Segment attached={i === testFunctionDescriptor.comments!.length - 1 ? "bottom" : true} key={i} className={i === 0 ? "TestFunctionComponent_segment3" : ""} >
                <CommentComponent comment={comment} />
            </Segment>
        ))}
        {parent?.state.modalOpenAtScreenshotWithIndex !== undefined && <RecordedTestModal parent={parent} recordedTest={parent.state.recordedTest!}
            onClose={() => parent!.setState({ modalOpenAtScreenshotWithIndex: undefined })}
            testPath={parent.getUrl()} currentSlide={parent.state.modalOpenAtScreenshotWithIndex} />}
    </div>
}