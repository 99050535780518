import ErrorStackParser from "error-stack-parser";
import { SourceCodeInfoFull, TraceMapCache, TraceMapCacheEntry } from "./TraceMapCache";
import * as ts from "typescript";

// it contains spaces, so a clash w/ an existing property/function is practically impossible
export const EXTENSIONS_FOR_CLASS = "$extensions for class$";

export type ExtensionsFromDecorators = { [property: string]: ExtensionFromDecorators } | undefined;

export interface ExtensionFromDecorators {
    scenario?: string;
    options?: ScenarioOptionsType;
    comments?: string[];
    retrieveCommentFromTsDocFromSourceMap?: () => Promise<void>;
}

export interface ScenarioOptionsType {

    /**
     * Similar to `it.only()`. If there is at least one scenario annotated w/ "only" => only those scenarios will run.
     * 
     * Aliased by the `@Only` annotation.
     */
    runOnlyThisScenario?: boolean;

    /**
     * If `true`, and if the current scenario is annotated w/ `@Only`, then the next scenario will be ran as well,
     * even if it is not annotated w/ `@Only`. And vice-versa: if the next scenario is annotated w/ `@Only`, and the 
     * current one is not, both will run. Multiple consecutive scenarios can be annotated; not just 2.
     */
    linkWithNextScenario?: boolean;
}

/////////////////////////////////////////////////////////////////////////////////////////
// Internal functions used by all annotations
/////////////////////////////////////////////////////////////////////////////////////////

let globalForPseudoAnnotationsPrototype: any | undefined;
let globalForPseudoAnnotationsProperty: string | undefined;

/**
 * We call a "pseudo" annotation a function whose name begins with "annotation". They are expected to contain only direct
 * calls to annotation functions. They exist for the case when this lib is used in a project that doesn't have decorators enabled.
 * 
 * Each decorator expects to be called in a dual mode. Either normal decorator, or "pseudo" decorator. For the latter case, we 
 * use these globals.
 */
export function setGlobalsForPseudoAnnotations(proto: any, property?: string) {
    globalForPseudoAnnotationsPrototype = proto;
    globalForPseudoAnnotationsProperty = property;
}

export function getOrCreateExtensions(proto: any) {
    if (!proto) {
        proto = globalForPseudoAnnotationsPrototype;
    }
    if (!proto) {
        throw new Error("Error while calling 'pseudo' annotation. Not called via native decorators, and the global for 'pseudo' decorators is undefined.");
    }
    let extensions: ExtensionsFromDecorators = proto.extensions;
    if (!extensions) {
        proto.extensions = extensions = {};
    }
    return extensions;
}

function getOrCreateExtension(proto: any, property?: string) {
    const extensions = getOrCreateExtensions(proto);
    if (globalForPseudoAnnotationsPrototype) {
        property = globalForPseudoAnnotationsProperty;
    }
    if (!property) {
        throw new Error("Error while calling 'pseudo' annotation. The property should have a value.");
    }
    let extension = extensions[property];
    if (!extension) {
        extensions[property] = extension = {};
    }
    return extension as ExtensionFromDecorators;
}

/**
 * By default returns the given function. If in "pseudo" annotations mode, then invokes it.
 */
function returnOrCall<F extends Function>(f: F): F {
    if (globalForPseudoAnnotationsPrototype) {
        f.apply(null);
    }
    return f;
}

/**
 * The initial implementation was based getting the stack trace from within the decorator. In vite this worked OK. But in CRA,
 * because of the decorators are implemented very differently, not near the target function. Hence we switched to adopt an AST
 * approach.
 * 
 * @see https://ts-ast-viewer.com
 * @see https://github.com/Microsoft/TypeScript/wiki/Using-the-Compiler-API
 */
function getTypeDoc(sourceCodeInfo: SourceCodeInfoFull, functionName: string) {

    /**
     * May be optimized, but I don't think it's needed. It doesn't "look" in a method; but outside,
     * I think it parses every instruction. However, this is rare for test classes.
     */ 
    function processNode(node: ts.Node): string | undefined {
        if (node.kind === ts.SyntaxKind.MethodDeclaration && (node as any).name.escapedText === functionName) {
            const comment = ts.getLeadingCommentRanges(sourceFile.getFullText(), node.pos);
            if (!comment) {
                return undefined;
            }
            return sourceCodeInfo.sourceCode.substring(comment[0].pos, comment[0].end);
        }

        return ts.forEachChild(node, processNode);
    }

    // in vite, one cacheEntry = transpiled file = 1 ts file. But in CRA, 1 cache entry = transpiled file = several ts files
    // hence we need to store multiple ts files per cache entry
    let sourceFiles: { [fileName: string]: ts.SourceFile } = sourceCodeInfo.cacheEntry["tsSourceFiles"];
    if (!sourceFiles) {
        sourceFiles = sourceCodeInfo.cacheEntry["tsSourceFiles"] = {};
    }
    let sourceFile: ts.SourceFile = sourceFiles[sourceCodeInfo.sourceFile];
    if (!sourceFile) {
        sourceFile = sourceFiles[sourceCodeInfo.sourceFile] = ts.createSourceFile("input.tsx", sourceCodeInfo.sourceCode, ts.ScriptTarget.ES2021, false, ts.ScriptKind.TSX);
    }
    const commentWithDelimiters = processNode(sourceFile);
    return commentWithDelimiters && removeCommentDelimiters(commentWithDelimiters);
}

function removeCommentDelimiters(commentWithDelimiters: string) {
    // the regex matches 1/ .../*, 2/ ...*/, 3/ ...*. The "..." means \s = space like chars
    // copied also to: com.crispico.foundation.testGoodies.TestGoodiesController.getMethodsWithComments()
    return commentWithDelimiters.replace(/^\s*\/\*\*|\s*\*\/\n?$|(?<=\n)\s*\*/g, "");
}

/////////////////////////////////////////////////////////////////////////////////////////
// Annotations
/////////////////////////////////////////////////////////////////////////////////////////

/**
 * Annotation (decorator) for test functions. It is similar to `it("...", () -> { ... })`.
 */
export function Scenario(scenario: string) {
    const stackFrames = ErrorStackParser.parse(new Error());
    const stackFrame = stackFrames[1]; // 0 = here

    return returnOrCall(function $DECORATOR$(target: any, property: string) {
        const extension = getOrCreateExtension(target, property);
        extension.scenario = scenario;

        if (!extension.comments) {
            extension.comments = [];
        }

        extension.retrieveCommentFromTsDocFromSourceMap = async () => {
            const sourceCodeInfo = await TraceMapCache.INSTANCE.getSourceCodeState(stackFrame.getFileName()!, stackFrame.getLineNumber()!, stackFrame.getColumnNumber()!);
            const comment = getTypeDoc(sourceCodeInfo!, property);
            if (comment) {
                extension.comments!.push(comment);
            }
        }
    });
}

/**
 * Annotation (decorator) for specifying additional options for scenarios (i.e. functions annotated w/ `@Scenario`).
 * 
 * @param options 
 */
export function ScenarioOptions(options: ScenarioOptionsType) {
    return returnOrCall(function $DECORATOR$(target: any, property: string) {
        const extension = getOrCreateExtension(target, property);
        extension.options = Object.assign(extension.options || {}, options);
    });
}

/**
 * Annotation (decorator) which is an alias for `@ScenarioOptions({ runOnlyThisScenario: true })`
 */
export function Only() {
    return returnOrCall(function $DECORATOR$(target: any, property: string) {
        const extension = getOrCreateExtension(target, property);
        extension.options = Object.assign(extension.options || {}, { runOnlyThisScenario: true });
    });
}

/**
 * Annotation (decorator) for adding a comment which is visible in the UI. Can be used multiple times for the same function,
 * for adding comments. E.g. sometimes we might prefer using several `@Comment("...")`, instead of a single one w/ a multi line string.
 * 
 * For scenarios, we recommend this instead of normal comments. 
 * 
 * In order to display comments in the UI, it is exponentially complex to extract normal comments from source files 
 * (e.g. while taking into account the heterogeneity of JS tool chains). Hence we created this annotation.
 */
export function Comment(comment: string) {
    return returnOrCall(function $DECORATOR$(target: any, property?: string): void {
        if (target && !property) { // if !target => "pseudo" annotations mode
            // called for a class
            property = EXTENSIONS_FOR_CLASS;
            target = target.prototype;
        }
        const extension = getOrCreateExtension(target, property);
        if (!extension.comments) {
            extension.comments = [];
        }
        extension.comments.splice(0, 0, comment);
    });
}
