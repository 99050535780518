import { FilterOperators } from "@crispico/foundation-gwt-js";
import { BlocklyReadOnly } from "@crispico/foundation-react/blockly/BlocklyReadOnly";
import { createFilterBlock } from "@crispico/foundation-react/components/CustomQuery/createFilterBlock";
import { Filter } from "@crispico/foundation-react/components/CustomQuery/Filter";
import { FilterPreview } from "@crispico/foundation-react/components/CustomQuery/FilterPreview";
import { FieldRendererProps, fieldRenderers } from "../fieldRenderersEditors";
import _ from "lodash";
import { Button, Checkbox, Label, Popup } from "semantic-ui-react";
import { FieldType } from "../FieldType";

export const FilterFieldRenderer = (props: FieldRendererProps) => {
    const { value, entity, fieldDescriptor } = props;
    const entityName: string = props.fieldDescriptor.entityDescriptor ? props.fieldDescriptor.entityDescriptor.name : entity[fieldDescriptor.fieldForEntityName];
    let filter: Filter | undefined;
    if (fieldDescriptor.fieldForJson) {
        const f = JSON.parse(entity[fieldDescriptor.fieldForJson]);
        if (f) {
            filter = f;
        }
    } else if (value) {
        const f = JSON.parse(value);
        if (f) {
            filter = f;
        }
    }
    filter = Filter.enableAllFilters(filter);
    return <>
        {filter ? <span>
            <Popup flowing on='click' trigger={<Button compact content='...' />}>
                <div className='FilterBar_div'>
                    {filter.filters!.length > 1 && <Label compact content={filter.operator} color={filter.operator === FilterOperators.forComposedFilter.and.value ? "blue" : "teal"} />}
                    {filter.filters!.map((f: Filter, index: number) => 
                        <Label key={index} className={'OnlyFilterBar_filterDiv'}>
                            <Checkbox checked={f.enabled ? true : false} />
                            <BlocklyReadOnly key={index}>{createFilterBlock(f, { entityDescriptorName: entityName }, false, false, true)}</BlocklyReadOnly>
                        </Label>)}
                </div>
            </Popup>
            {props.fieldDescriptor.showPreviewButton !== false ? <FilterPreview entityName={entityName} getFilter={() => filter} /> : null}
        </span> : null}
    </>
}"../../blockly/BlocklyReadOnly""../../components/CustomQuery/createFilterBlock""../../components/CustomQuery/Filter""../../components/CustomQuery/FilterPreview"