import { produce } from "immer";
import React from "react";
import { Button, Checkbox, Label, Message, Popup } from "semantic-ui-react";
import { SplitPaneExt } from "../../copied/ReactSplitPaneExt/ReactSplitPaneExt";
import { LOCAL_STORAGE_CURRENT_TEST_CLASS, QUERY_STRING_TESTS_TO_RUN, TestClassDescriptor, TestsAreDemoMaster, TestsToRun } from "../TestsAreDemoMaster";
import { FeatureListProps, FeaturesList } from "./FeatureList";
import { TestClassComponent, TestClassComponentProps } from "./TestClassComponent";
import "./styles.css";
import { getLinkToCurrentPage, getTestToRunCountLabel, getTestsCountLabel, getTestsToRunCount } from "./functions";
import { TestsClassNameRenderer } from "./TestClassNameRenderer";

export interface FeaturebookCommonProps {
    master?: TestsAreDemoMaster;
    running?: boolean;
    testsToRun?: TestsToRun;
}

export interface FeaturebookUiProps extends FeaturebookCommonProps {
    testClassDescriptors?: TestClassDescriptor[];
}

export interface FeaturebookUiState {
    selectedDescriptor?: TestClassDescriptor;
}

export class Featurebook<P extends FeaturebookUiProps = FeaturebookUiProps, S extends FeaturebookUiState = FeaturebookUiState> extends React.Component<P, S> {

    // @ts-ignore
    state: S = {};

    constructor(props: P) {
        super(props);
        this.componentDidUpdate();
    }

    componentDidUpdate(prevProps?: Readonly<P>): void {
        // maybe some classes/functions stored in LocalStorage don't exist anymore; so let's remove them
        // w/o this, the counters would be wrong
        const props = this.props;
        if (props.testClassDescriptors === prevProps?.testClassDescriptors || !props.testsToRun) {
            return;
        }

        let wasUpdated = false;
        const testsToRunUpdated = produce(props.testsToRun!, draft => {
            for (let testClass of Object.getOwnPropertyNames(draft)) { // I use .getOwnPropertyNames() because I'm deleting properties. I don't know if this would impact the "normal" iteration
                const testClassDescriptor = props.testClassDescriptors!.find(d => d.name === testClass);
                if (!testClassDescriptor) {
                    delete draft[testClass];
                    wasUpdated = true;
                    continue;
                }
                const functionsToRun = draft[testClass];
                for (let functionName of Object.getOwnPropertyNames(functionsToRun)) {
                    if (!testClassDescriptor.functions.find(f => f.functionName === functionName)) {
                        delete functionsToRun[functionName];
                        wasUpdated = true;
                    }
                }
            }
        });
        if (wasUpdated) {
            props.master!.updateTestsToRun(testsToRunUpdated);
        }
    }

    protected getShowLeftPane(): boolean | undefined {
        return true;
    }

    protected getTestClassDescriptors() {
        return this.props.testClassDescriptors;
    }

    protected getAdditionalPropsForFeatureList(): Partial<FeatureListProps> {
        return { localStorageKey: LOCAL_STORAGE_CURRENT_TEST_CLASS };
    }

    /**
     * Also used as condition to know if this is FeaturebookUi or FeaturebookUiRecorded
     */
    protected getAdditionalPropsForTestClassComponent(): TestClassComponentProps | undefined {
        return undefined;
    }

    protected renderTestClassComponent() {
        let { testsToRun, master } = this.props;
        return <TestClassComponent master={master} testsToRun={testsToRun} testClassDescriptor={this.state.selectedDescriptor} {...this.getAdditionalPropsForTestClassComponent()} />
    }

    protected setSelectedDescriptor(selectedDescriptor: TestClassDescriptor) {
        this.setState({ selectedDescriptor });
    }

    protected renderAdditionalsInHeader() {
        return <></>
    }

    render() {
        let { testsToRun, master } = this.props;

        if (!this.getShowLeftPane()) {
            return this.renderTestClassComponent();
        }

        let linkFragment = testsToRun && JSON.stringify(testsToRun);
        let link: string | undefined;
        if (linkFragment) {
            link = getLinkToCurrentPage(QUERY_STRING_TESTS_TO_RUN, linkFragment);
            const search = new URLSearchParams();
            search.append(QUERY_STRING_TESTS_TO_RUN, linkFragment)
            linkFragment = "\\&" + search.toString();
        }
        const checked = getTestsToRunCount(testsToRun);

        return (<>
            <SplitPaneExt size={"25%"} split="vertical">
                <div className="w100">
                    {!this.getAdditionalPropsForTestClassComponent() && <>
                        <Popup flowing on="click" trigger={<Button icon="linkify" content="Link" />}>
                            {!linkFragment ? "No test is checked to run" : <>
                                <p>
                                    {getTestToRunCountLabel(testsToRun)}: there are {checked.functionsChecked} test function(s) (in {checked.classesChecked} class(es)) checked to be ran.
                                </p>
                                <p>
                                    <Label circular content="1" /> This <a href={link} target="_blank">shareable link</a> contains in its search string the JSON for the checked tests to run.
                                </p>
                                <p>
                                    <Label circular content="2" /> When launched from <b>puppeteer</b>, all the tests are ran. If you want to <b>filter</b>, then you can append a command line fragment to the puppeteer command line e.g. <Label horizontal><code>tad-puppeteer \&testsToRun=...</code></Label>:
                                </p>
                                <Message><code>{linkFragment}</code></Message>
                                <Button content="Copy to clipboard" onClick={() => {
                                    navigator.clipboard.writeText(linkFragment!);
                                    alert("Copied to clipboard!");
                                }} />
                            </>}
                        </Popup>
                        <Button disabled={this.props.running} icon="play" content={"Run " + getTestToRunCountLabel(testsToRun)} onClick={e => master!.run(true)} />
                    </>}
                    {this.getTestClassDescriptors() && <Label basic horizontal color="grey">
                        {getTestsCountLabel(this.getTestClassDescriptors()!)}
                    </Label>}
                    {this.renderAdditionalsInHeader()} 
                    <FeaturesList items={this.getTestClassDescriptors()}
                        getIdForLocalStorage={(descriptor: TestClassDescriptor) => descriptor.name}
                        onSelect={(selectedDescriptor: TestClassDescriptor) => this.setSelectedDescriptor(selectedDescriptor)}
                        renderItem={(descriptor: TestClassDescriptor) => {
                            const checked = testsToRun && testsToRun[descriptor.name] && Object.getOwnPropertyNames(testsToRun[descriptor.name]).length > 0;
                            return <>
                                {!this.getAdditionalPropsForTestClassComponent() && <Checkbox checked={checked} label="&nbsp;" onChange={() => {
                                    if (!testsToRun) {
                                        testsToRun = {};
                                    }
                                    master?.updateTestsToRun(produce(testsToRun!, draft => {
                                        if (checked) {
                                            delete draft[descriptor.name];
                                        } else {
                                            // @ts-ignore
                                            const clazz = draft[descriptor.name] = {} as TestsToRun["something"];
                                            for (let f of descriptor.functions) {
                                                clazz[f.functionName] = true;
                                            }
                                        }
                                    }));
                                }} />}
                                <TestsClassNameRenderer name={descriptor.name} />
                            </>
                        }} {...this.getAdditionalPropsForFeatureList()} />
                </div>
                {this.renderTestClassComponent()}
            </SplitPaneExt>
        </>)
    }
}