import { Formik } from "formik";
import { getBaseReducers, createSliceFoundation, PropsFrom } from "@crispico/foundation-react/reduxHelpers";
import { FieldNameContentAssist, FieldNameContentAssistRRC, FormType } from "../fieldNameContentAssist/FieldNameContentAssist";
import { Sort } from "./SortBar";
import React from "react";
import { Form, Button, Icon, Modal, Message } from "semantic-ui-react";
import lodash from 'lodash';
import { ModalExt } from "../ModalExt/ModalExt";

export const sliceSortForm = createSliceFoundation(class SliceSortForm {

    nestedSlices = {}

    reducers = {
        ...getBaseReducers<SliceSortForm>(this),
    }

});

interface SortFormProps {
    indexForEditor: number,
    entityName: any,
    embeddedMode: boolean,
    onSave: any,
    closeSortEditor: any,
    moveLeft: any,
    moveRight: any,
    remove: any,
    formOpened: boolean | [number, number],
    sorts: Array<Sort>,
    error: boolean
}

export class SortForm extends React.Component<PropsFrom<typeof sliceSortForm> & SortFormProps> {
    sort!: Sort;

    public fieldNameContentAssistRef: FieldNameContentAssist | null = null;

    getChain() {
        if (this.fieldNameContentAssistRef) {
            return lodash.cloneDeep(this.fieldNameContentAssistRef.getChain());
        }
    }

    getField() {
        let field = '';
        this.getChain()!.forEach((e: any) => {
            field += e.field + '.';
        });
        field = field.slice(0, field.length - 1);
        this.sort.field = field;

        return field;
    }

    onFieldNameContentAssistRefChange = (node: any) => {
        this.fieldNameContentAssistRef = node;
        if (node != null) {
            if (this.props.indexForEditor === -1) {
                let includeManyToOneFields = this.fieldNameContentAssistRef?.props.includeManyToOneFields;
                let includeScalarFields = this.fieldNameContentAssistRef?.props.includeScalarFields;
                this.fieldNameContentAssistRef?.props.r.open(this.props.entityName, includeManyToOneFields, includeScalarFields, FormType.SORT);
            }
        }
    };

    render() {
        const initialValues = this.props.indexForEditor >= 0 ? this.props.sorts[this.props.indexForEditor] : { field: '', direction: 'ASC' };
        let formikPropsCopy: any;
        return (
            <>
                <ModalExt onClose={this.props.closeSortEditor} open={this.props.formOpened} addNiceLookingOffsets size='tiny'>
                    <Modal.Header>{_msg('SortBar.sortForm.title')}</Modal.Header>
                    <Modal.Content>  {/* CZ: Didn't use 'scrolling' prop because the dropdown from FieldNameContentAssist wouldn't show over all elements (overflow problems)*/}
                        {this.props.error && <Message error header={_msg('SortBar.invalid')} content={_msg('SortBar.invalidMessage')} />}
                        <Formik initialValues={initialValues} onSubmit={() => { }}>
                            {(formikProps: any) => {
                                formikPropsCopy = formikProps;
                                this.sort = { ...formikProps.values };
                                if (this.fieldNameContentAssistRef && this.getChain()!.length > 0) {
                                    this.sort.field = this.getField();
                                }
                                return (
                                    <Form>
                                        <Form.Field data-testid="SortForm_field">
                                            {_msg('Sort.field.label')}
                                            <FieldNameContentAssistRRC id='fieldNameContentAssist' ref={this.onFieldNameContentAssistRefChange} formType={FormType.SORT} rootEntity={this.props.entityName!} field={this.sort.field} includeManyToOneFields={true} />
                                        </Form.Field>
                                        <Form.Field>
                                            {_msg('Sort.direction.label')}
                                            <Form.Group>
                                                <Form.Radio data-testid="SortForm_asc"
                                                    label='ASC'
                                                    value='ASC'
                                                    name='direction'
                                                    checked={this.sort.direction === 'ASC'}
                                                    onChange={(event: any, data: any) => { formikProps.setFieldValue('direction', data.value) }}
                                                />
                                                <Form.Radio data-testid="SortForm_desc"
                                                    label='DESC'
                                                    value='DESC'
                                                    name='direction'
                                                    checked={this.sort.direction === 'DESC'}
                                                    onChange={(event: any, data: any) => { formikProps.setFieldValue('direction', data.value) }}
                                                />
                                            </Form.Group>

                                        </Form.Field>
                                    </Form>)
                            }}
                        </Formik>
                    </Modal.Content>
                    <Modal.Actions className='SortForm_modalActions'>
                        <Button.Group>
                            <Button data-testid="SortForm_apply" onClick={() => { this.sort.field = this.getField(); formikPropsCopy.resetForm(); this.props.onSave(this.sort); }} color='green'>{_msg('general.apply')}</Button>
                            <Button onClick={() => { formikPropsCopy.resetForm(); this.props.closeSortEditor(); }} color='red'>{_msg('general.cancel')}</Button>
                        </Button.Group>
                        <Button.Group>
                            <Button onClick={this.props.moveLeft} disabled={this.props.indexForEditor === -1 ? true : false}><Icon name={this.props.embeddedMode ? 'arrow up' : 'arrow left'} />{!this.props.embeddedMode && _msg('general.move')}</Button>
                            <Button onClick={this.props.moveRight} disabled={this.props.indexForEditor === -1 ? true : false}>{!this.props.embeddedMode && _msg('general.move')}<Icon name={this.props.embeddedMode ? 'arrow down' : 'arrow right'} /></Button>
                            <Button onClick={this.props.remove} disabled={this.props.indexForEditor === -1 ? true : false} color='red'>{_msg('general.remove')}</Button>
                        </Button.Group>
                    </Modal.Actions>
                </ModalExt>
            </>
        );
    }
}
"../../reduxHelpers"