import { Button, ButtonGroup, ButtonOr, Divider, Dropdown, DropdownItem, DropdownMenu, Input, Label } from "semantic-ui-react";
import { LOCAL_STORAGE_CURRENT_TEST_CLASS_RECORDED, LOCAL_STORAGE_RECORDED_TESTS_URL, LOCAL_STORAGE_RECORDED_TESTS_URL_RECENT, QUERY_STRING_RECORDED_TESTS_URL, TestClassDescriptor } from "../TestsAreDemoMaster";
import { Featurebook, FeaturebookUiProps, FeaturebookUiState } from "./Featurebook";
import { FeatureListProps } from "./FeatureList";
import { Utils } from "../../copied/Utils";

// @ts-ignore
import d from "../../common-lib-and-node/common.mjs";
const { RECORDED_TESTS_INDEX } = (d as CommonLibAndNodeDefaultExport);
import { CommonLibAndNodeDefaultExport } from "../../common-lib-and-node/common-types";
import { TestClassComponentProps } from "./TestClassComponent";
import { fetchJsonCredentials, getLinkToCurrentPage } from "./functions";

interface FeatureBookUiRecordedProps extends FeaturebookUiProps {
    recordedTestsUrl: string;
    selectedTestClass?: string;
    showLeftPane?: boolean;
    includeCredentialsInJsonRequestRegex?: RegExp;
}

interface FeaturebookUiRecordedState extends FeaturebookUiState {
    recordedTestsUrl: string;
    recordedTestsUrlEditing: string;
    recent: string[]
    recordedTestClassDescriptors?: TestClassDescriptor[];
    includeCredentialsInJsonRequest?: boolean;
}

const maxRecent = 10;

/**
 * Meant to be included stand alone in Docusaurus. For testing, there is src/app/indexFeaturebookUiRecorded.html.
 */
export class FeaturebookUiRecorded extends Featurebook<FeatureBookUiRecordedProps, FeaturebookUiRecordedState> {

    constructor(props: FeatureBookUiRecordedProps) {
        super(props);

        let recordedTestsUrl: string | null;
        let updateLocalStorage = true;
        if (!props.showLeftPane) {
            // in the "docusaurus" mode, we don't want to take into account what's in local storage. I don't have a visual feedback 
            // that what I'm seeing is "overridden", and this can be misleading
            recordedTestsUrl = props.recordedTestsUrl;
            updateLocalStorage = false;
        } else {
            recordedTestsUrl= new URLSearchParams(window.location.search).get(QUERY_STRING_RECORDED_TESTS_URL);
            if (!recordedTestsUrl) {
                recordedTestsUrl = localStorage.getItem(LOCAL_STORAGE_RECORDED_TESTS_URL) || props.recordedTestsUrl;
                updateLocalStorage = false;
            }
        }
        const recentStr = localStorage.getItem(LOCAL_STORAGE_RECORDED_TESTS_URL_RECENT);
        const recent = recentStr ? JSON.parse(recentStr) : [];

        this.state = { recordedTestsUrl, recordedTestsUrlEditing: recordedTestsUrl, recent };
        this.recordedTestsUrlChanged(recordedTestsUrl, updateLocalStorage, recordedTestsUrl !== props.recordedTestsUrl);
    }

    protected async recordedTestsUrlChanged(recordedTestsUrl: string, updateLocalStorage: boolean, updateRecent: boolean) {
        let recordedTestClassDescriptors: TestClassDescriptor[] | undefined = undefined;
        const includeCredentialsInJsonRequest = this.props.includeCredentialsInJsonRequestRegex?.test(recordedTestsUrl);
        try {
            const url = Utils.substringBefore(recordedTestsUrl, "/", true);
            recordedTestClassDescriptors = await fetchJsonCredentials<TestClassDescriptor[]>(url + "/testClassDescriptors.json", includeCredentialsInJsonRequest);
        } catch {
            // nop
        }

        this.setState({ recordedTestClassDescriptors, recordedTestsUrl, recordedTestsUrlEditing: recordedTestsUrl, includeCredentialsInJsonRequest });
        if (updateLocalStorage) {
            localStorage.setItem(LOCAL_STORAGE_RECORDED_TESTS_URL, recordedTestsUrl);
        }
        if (updateRecent) {
            const { recent } = this.state;
            const existingIndex = recent.indexOf(recordedTestsUrl);
            let newRecent: string[];
            if (existingIndex < 0) {
                newRecent = recent.slice(0, maxRecent - 1);
                newRecent.splice(0, 0, recordedTestsUrl);
            } else {
                newRecent = [...recent];
                newRecent[0] = recent[existingIndex];
                newRecent[existingIndex] = recent[0];
            }
            this.setState({ recent: newRecent });
            localStorage.setItem(LOCAL_STORAGE_RECORDED_TESTS_URL_RECENT, JSON.stringify(newRecent));
        }

        // select the test class; probably from within docusaurus
        if (this.props.selectedTestClass && recordedTestClassDescriptors) {
            for (let descriptor of recordedTestClassDescriptors) {
                if (descriptor.name === this.props.selectedTestClass) {
                    this.setState({ selectedDescriptor: descriptor });
                    break;
                }
            }
        }
    }

    componentDidUpdate(prevProps?: Readonly<FeatureBookUiRecordedProps> | undefined): void {
        // nop
    }

    protected getShowLeftPane() {
        return this.props.showLeftPane;
    }

    protected getTestClassDescriptors() {
        return this.state.recordedTestClassDescriptors;
    }

    protected getAdditionalPropsForFeatureList(): Partial<FeatureListProps> {
        return {
            localStorageKey: LOCAL_STORAGE_CURRENT_TEST_CLASS_RECORDED,
            selectedImposed: this.props.selectedTestClass
        };
    }

    protected getAdditionalPropsForTestClassComponent(): TestClassComponentProps | undefined {
        return { 
            recordedTestsUrl: this.state.recordedTestsUrl,
            includeCredentialsInJsonRequest: this.state.includeCredentialsInJsonRequest,
        };
    }

    render() {
        return <div className="FeaturebookUiRecorded">
            {this.getShowLeftPane() && <>
                <div className="FeaturebookUiRecorded_bar">
                    <span>
                        <b>URL for <code>{RECORDED_TESTS_INDEX}</code>:</b>&nbsp;
                        <br />
                        <a href={getLinkToCurrentPage(QUERY_STRING_RECORDED_TESTS_URL, this.state.recordedTestsUrl)} target="_blank">(shareable link)</a>
                    </span>
                    <Input value={this.state.recordedTestsUrlEditing} onChange={(e, data) => this.setState({ recordedTestsUrlEditing: data.value })} />&nbsp;
                    {this.state.recordedTestsUrlEditing !== this.state.recordedTestsUrl && <>
                        <Label color="brown" basic>Editing ...</Label>
                        <ButtonGroup>
                            <Button positive content="Apply" onClick={e => this.recordedTestsUrlChanged(this.state.recordedTestsUrlEditing, true, true)} />
                            <ButtonOr />
                            <Button negative content="Cancel" onClick={e => this.setState({ recordedTestsUrlEditing: this.state.recordedTestsUrl })} />
                        </ButtonGroup>
                        &nbsp;
                    </>}
                    <Button content="Default" onClick={e => this.recordedTestsUrlChanged(this.props.recordedTestsUrl, true, false)} />
                    <Dropdown text='Recent' icon='history' floating labeled button className='icon' direction="left">
                        <DropdownMenu>
                            {this.state.recent.map((r, i) => <DropdownItem key={i} icon="history" text={r} onClick={e => this.recordedTestsUrlChanged(r, true, true)} />)}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <Divider />
            </>}

            {super.render()}
        </div>
    }
}