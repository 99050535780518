import React from "react";
import { Size } from "react-split-pane";
import { Button, Checkbox, Divider, Dropdown, DropdownProps, Grid, Label, Menu, Message, Modal, Popup, Segment, Sidebar } from "semantic-ui-react";
import { SplitPaneExt } from "../copied/ReactSplitPaneExt/ReactSplitPaneExt";
import { Utils } from "../copied/Utils";
import { MarkdownExt } from "./MarkdownExt";
import { ReporterFromSlaveToMaster } from "./ReporterFromSlaveToMaster";
import { SourceCode } from "./SourceCode";
import { createTestids } from "./TestsAreDemoFunctions";
import { TestsAreDemoSlave } from "./TestsAreDemoSlave";
import { SourceCodeInfo } from "./TraceMapCache";
import "./copied-from-foundation.css";
import { DocFromTests } from "./docFromTests/DocFromTests";
import { ScreenshotsFromTests } from "./docFromTests/ScreenshotsFromTests";
import { FeatureBookServer } from "./featureBookServer/FeatureBookServer";
import { Featurebook } from "./featurebook/Featurebook";
import { MiniDbTableSetComponent } from "./miniDb/MiniDbTableSetComponent";
import "./tests-are-demo.css";
import { PuppeteerBridge } from "./PuppeteerBridge";
import { FeaturebookUiRecorded } from "./featurebook/FeaturebookRecorded";
import { FeaturebookServer } from "./featurebook/FeaturebookServer";
import { getTestToRunCountLabel } from "./featurebook/functions";
import { CaptureUiApiEvents } from "./uiApi/CaptureUiApiEvents";
import { FoundationUtils } from "@famiprog-foundation/utils";
import { CommonLibAndNodeDefaultExport, PuppeteerMessageType } from "../common-lib-and-node/common-types";
// @ts-ignore
import d from "../common-lib-and-node/common.mjs";
const { MESSAGE_FOR_PUPPETEER, RECORDED_TESTS_DIR, RECORDED_TESTS_INDEX } = (d as CommonLibAndNodeDefaultExport);


export enum StepByStepMode { OFF, ON, CONTROLLED_BY_PROGRAM }

// also in com.crispico.foundation.testGoodies.TestGoodiesController
export interface TestClassDescriptor {
    testClass?: any;
    name: string;
    comments?: string[];
    functions: TestFunctionDescriptor[];
    hooks?: string[];
}

export interface TestFunctionDescriptor {
    functionName: string;
    scenario?: string;
    comments?: string[];
}

export enum MenuState { TAD, FB, FB_RECORDED, FB_SERVER, FB_SERVER_OLD, DOC, SCREENSHOTS }
export enum TabState { SRC, TREE, INSPECT, MINI_DB, CAPTURE_UI_API_EVENTS }

export type TestsToRun = {
    [className: string]: {
        [functionName: string]: boolean
    }
}

interface MasterState {
    menuState: MenuState;
    tabState: TabState;
    sidebar: boolean;
    finishWaitingCallback?: Function;
    running: boolean;
    loggedMessages: string;
    horizontalLayout: boolean;
    size1?: Size;
    size2?: Size;
    autoPressNextEnabled: boolean;
    autoPressNextAt?: number;
    // normally the result should be a number (in the browser); but the TS typings (coming from node.js?) have another type
    autoPressTimerId?: any;
    stepByStepMode: StepByStepMode;
    endUser: boolean;
    inspecting?: boolean;
    inspectedElements?: HTMLElement[];
    justCopiedToClipboard?: boolean;
    testClassDescriptorsLoadingPromise?: Promise<void>;
    testClassDescriptors?: TestClassDescriptor[];
    testsToRun?: TestsToRun;
    // here and not in the internal state of SourceCode, because when changing the tab, SourceCode is dismounted; so
    // the state is lost. And we don't want this.
    sourceCodeInfo?: SourceCodeInfo,
    capturedUiApiCalls?: string,
}

export interface MasterProps {
    puppeteer?: boolean,
    forceStepByStep?: boolean,
    serverUrlPrefix: string
}

declare global {
    interface Window {
        testsAreDemoMaster?: TestsAreDemoMaster;
    }
}

// the following are overridden by the mode "puppeteer"
export const LOCAL_STORAGE_STEP_BY_STEP = "testsAreDemo.stepByStep";
export const LOCAL_STORAGE_AUTO_RUN = "testsAreDemo.autoRun";
const LOCAL_STORAGE_AUTO_PRESS_NEXT = "testsAreDemo.autoPressNext";
const LOCAL_STORAGE_END_USER = "testsAreDemo.endUser";

const LOCAL_STORAGE_HORIZONTAL_LAYOUT = "testsAreDemo.horizontalLayout";
const LOCAL_STORAGE_SIZE1 = "testsAreDemo.size1";
const LOCAL_STORAGE_SIZE2 = "testsAreDemo.size2";
export const LOCAL_STORAGE_MENU_STATE = "testsAreDemo.menuState";
export const LOCAL_STORAGE_TAB_STATE = "testsAreDemo.tabState";
export const LOCAL_STORAGE_CURRENT_CLASS_NAME = "testsAreDemo.currentClassName";
export const LOCAL_STORAGE_CURRENT_METHOD_NAME = "testsAreDemo.currentMethodName";
export const LOCAL_STORAGE_CURRENT_DOC = "testsAreDemo.currentDoc";
export const LOCAL_STORAGE_CURRENT_DIR_WITH_SCREENSHOTS = "testsAreDemo.currentDirWithScreenshots";
export const LOCAL_STORAGE_TESTS_TO_RUN = "testsAreDemo.testsToRun";
export const LOCAL_STORAGE_CURRENT_TEST_CLASS = "testsAreDemo.currentTestClass";
export const LOCAL_STORAGE_CURRENT_TEST_CLASS_RECORDED = "testsAreDemo.currentTestClassRecorded";
export const LOCAL_STORAGE_RECORDED_TESTS_URL = "testsAreDemo.recordedTestsUrl";
export const LOCAL_STORAGE_RECORDED_TESTS_URL_RECENT = "testsAreDemo.recordedTestsUrlRecent";

export const QUERY_STRING_TESTS_TO_RUN = "testsToRun";
export const QUERY_STRING_RECORDED_TESTS_URL = "recordedTestsUrl";

const testids = createTestids("TestsAreDemoMaster", {
    nextStep: "", run: ""
});
export const testsAreDemoMasterTestids = testids;

/**
 * Holds the IFrame, control panel, and log (for mocha).
 */
export class TestsAreDemoMaster<P extends MasterProps = MasterProps> extends React.Component<P, MasterState> {

    state: MasterState = {
        menuState: MenuState.FB,
        tabState: TabState.SRC,
        sidebar: false,
        running: false,
        loggedMessages: "",
        horizontalLayout: false,
        autoPressNextEnabled: false,
        stepByStepMode: StepByStepMode.OFF,
        endUser: false,
    }

    testIdProp = "data-testid";

    refMiniDbTableSetComponent = React.createRef<MiniDbTableSetComponent>();

    refCaptureUiApiEvents = React.createRef<CaptureUiApiEvents>();

    puppeteerBridge = new PuppeteerBridge();

    private _slave?: TestsAreDemoSlave;

    public get slave(): TestsAreDemoSlave {
        if (!this._slave) {
            // UPDATE: since we are rendering the UI only if slave exists, I don't think it's possible any more to enter here
            throw new Error("TestsAreDemoSlave (which should normally sit in the IFrame) did not connect to TestsAreDemoMaster");
        }
        return this._slave;
    }

    /**
     * Called when the slave "connects" to the master. 1) on page refresh. 2) if only the IFrame/slave refreshes.
     * I don't know why, but regarding HMR/auto refresh of CRA/Webpack: sometimes both master + slave are refreshed. 
     * But other times, only the slave refreshes.
     */
    public set slave(value: TestsAreDemoSlave) {
        this._slave = value;
        this.readStepByStep();
        this.forceUpdate(); // to show the checkbox at startup
        if (this.getAutoRun()) {
            setTimeout(() => this.run()); // setTimeout() is just in case; I'm afraid that starting the tests during the initialization may generate some glitches
        }
    }

    /**
     * Although the creation happens here, the corresponding field is in TADSlave because I saw that this class doesn't have much fields.
     * All fields seem to be concentrated in TADSlave
     */
    onMochaReporterCreated(reporter: Mocha.reporters.Base, runner: Mocha.Runner, options: Mocha.MochaOptions): ReporterFromSlaveToMaster {
        return new ReporterFromSlaveToMaster(this, reporter, runner, options);
    }

    componentDidMount() {
        // NOTE: cf. https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects, the constructor IS NOT a good
        // place to put such code, because of the double invocation
        if (window.testsAreDemoMaster) {
            throw new Error("Cannot create more than one instances of TestsAreDemoMaster");
        }
        window.testsAreDemoMaster = this; // to communicate this to the slave
        if ("true" === localStorage.getItem(LOCAL_STORAGE_HORIZONTAL_LAYOUT)) {
            this.setState({ horizontalLayout: true });
        }
        // using parseInt, because Number(null) returns 0
        const size1 = parseInt(localStorage.getItem(LOCAL_STORAGE_SIZE1) || "");
        if (!isNaN(size1)) {
            this.setState({ size1 });
        }
        const size2 = parseInt(localStorage.getItem(LOCAL_STORAGE_SIZE2) || "");
        if (!isNaN(size2)) {
            this.setState({ size2 });
        }
        const menuState = parseInt(localStorage.getItem(LOCAL_STORAGE_MENU_STATE) || "");
        if (!isNaN(menuState)) {
            this.setState({ menuState });
        }
        const tabState = parseInt(localStorage.getItem(LOCAL_STORAGE_TAB_STATE) || "");
        if (!isNaN(tabState)) {
            this.setState({ tabState });
        }

        if (!this.props.puppeteer) {
            if ("true" === localStorage.getItem(LOCAL_STORAGE_AUTO_PRESS_NEXT)) {
                this.setState({ autoPressNextEnabled: true });
            }
            if ("true" === localStorage.getItem(LOCAL_STORAGE_END_USER)) {
                this.setState({ endUser: true });
            }
        }

        const search = new URLSearchParams(window.location.search);
        // case 1: from the URL search string
        // case 2: from the local storage AND we are not in puppeteer mode. For puppeteer, we don't want to read from local storage, because maybe there is somehow data
        const testsToRunString = search.get(QUERY_STRING_TESTS_TO_RUN) || !this.props.puppeteer && localStorage.getItem(LOCAL_STORAGE_TESTS_TO_RUN);
        if (testsToRunString) {
            this.setState({ testsToRun: JSON.parse(testsToRunString) });
        }
    }

    readStepByStep() {
        let stepByStepMode: StepByStepMode;
        const stored = localStorage.getItem(LOCAL_STORAGE_STEP_BY_STEP);

        if (this.props.forceStepByStep) {
            // probably puppeteer in "take screenshots" mode
            // in theory might be invoked in browser as well; but I don't see an interest
            stepByStepMode = StepByStepMode.ON;
        } else if (this.props.puppeteer) {
            stepByStepMode = StepByStepMode.OFF
        } else if (stored === null || isNaN(Number(stored))) {
            stepByStepMode = StepByStepMode.ON;
        } else {
            stepByStepMode = Number(stored);
        }

        this.slave.stepByStep = stepByStepMode === StepByStepMode.ON;
        this.setState({ stepByStepMode });
    }

    protected getAutoRun() {
        if (this.props.puppeteer) {
            return true;
        }
        // default is true
        return "false" === localStorage.getItem(LOCAL_STORAGE_AUTO_RUN) ? false : true;
    }

    updateTestsToRun(testsToRun: TestsToRun) {
        this.setState({ testsToRun });
        localStorage.setItem(LOCAL_STORAGE_TESTS_TO_RUN, JSON.stringify(testsToRun));
    }

    log(what: string) {
        this.setState({ loggedMessages: this.state.loggedMessages + "\n" + what });
    }

    sendMessageToPuppeteer(messageType: PuppeteerMessageType, message: any) {
        if (this.props.puppeteer) {
            console.log(`${MESSAGE_FOR_PUPPETEER} ${messageType} ${JSON.stringify(message)}`);
        } // else don't print; we don't want to pollute the console
    }

    run(switchToTab = false) {
        if (this.state.menuState !== MenuState.TAD) {
            if (switchToTab) {
                this.onModifMenuState(MenuState.TAD);
            } else if (!this.props.puppeteer) {
                // i.e. we are in the auto run mode, but on another tab; we don't want it to start
                return;
            }
        }

        this.setState({ running: true, loggedMessages: "" });
        this.slave.run();
    }

    onRunFinished() {
        this.setState({ running: false });
        this.readStepByStep(); // because maybe clicked on "Run normally", which changed the attribute, but not local storage
    }

    nextStep() {
        this.disableAutoPressTimerIfEnabled();
        this.slave.hideSpotlight();
        this.state.finishWaitingCallback!();
        this.setState({ finishWaitingCallback: undefined });
        // "ack", so that we won't have error on next showSpotlight()
        this.slave.report.messageSentToPuppeteer = undefined;
    }

    public enableNextStepButton(finishWaitingCallback: Function,) {
        this.setState({ finishWaitingCallback });

        if (this.state.autoPressNextEnabled) {
            this.enableAutoPressTimer();
        }
    }

    protected disableAutoPressTimerIfEnabled(shouldClear: boolean = true) {
        if (shouldClear) {
            clearTimeout(this.state.autoPressTimerId);
        }
        this.setState({ autoPressNextAt: undefined, autoPressTimerId: undefined });
    }

    protected enableAutoPressTimer() {
        this.setState({ autoPressNextAt: Utils.now().getTime() + 3000 });
        this.onAutoPressTimerTick();
    }

    protected onAutoPressTimerTick = () => {
        if (this.state.autoPressNextAt && Utils.now().getTime() > this.state.autoPressNextAt) {
            this.disableAutoPressTimerIfEnabled(false);
            this.nextStep();
        } else {
            this.setState({ autoPressTimerId: setTimeout(this.onAutoPressTimerTick, 1000) });
            this.forceUpdate();
        }
    }

    protected getIFrameUrl(locationOrigin: string, locationPathname: string, locationSearch: URLSearchParams) {
        return locationOrigin + locationPathname + "?" + locationSearch.toString();
    }

    protected renderIFramePane() {
        // we use this to operate on the search string (instead of simply putting ?tests...) because maybe the
        // initial page itself has some additional params
        const search = new URLSearchParams(window.location.search);
        search.delete("TestsAreDemo");
        search.append("testsAreDemoSlave", "");

        return <div className="flex-container flex-grow">
            <iframe width="100%" height="100%" src={this.getIFrameUrl(window.location.origin, window.location.pathname, search)} />
        </div>
    }

    protected onModifTabState = (tabState: TabState) => {
        this.setState({ tabState });
        localStorage.setItem(LOCAL_STORAGE_TAB_STATE, "" + tabState);
    }

    protected renderTabs() {
        const { tabState } = this.state;
        return <div className="flex-container flex-grow">
            <Menu attached="top" tabular>
                <Menu.Item active={TabState.SRC === tabState} onClick={() => this.onModifTabState(TabState.SRC)}>Source</Menu.Item>
                <Menu.Item active={TabState.TREE === tabState} onClick={() => this.onModifTabState(TabState.TREE)}>Tree</Menu.Item>
                <Menu.Item active={TabState.INSPECT === tabState} onClick={() => this.onModifTabState(TabState.INSPECT)}>Inspect testid</Menu.Item>
                <Menu.Item active={TabState.MINI_DB === tabState} onClick={() => this.onModifTabState(TabState.MINI_DB)}>Mini DB</Menu.Item>
                <Menu.Item active={TabState.CAPTURE_UI_API_EVENTS === tabState} onClick={() => this.onModifTabState(TabState.CAPTURE_UI_API_EVENTS)}>Capture UiApi events</Menu.Item>
            </Menu>
            <Segment attached="bottom" className={"flex-grow flex-container " + (TabState.SRC === tabState ? "no-padding-margin " : "")}>
                {TabState.SRC === tabState && <SourceCode sourceCodeInfo={this.state.sourceCodeInfo} />}
                {TabState.TREE === tabState && <textarea readOnly wrap="off" style={{ width: "100%", height: "100%" }} value={this.state.loggedMessages} />}
                {TabState.INSPECT === tabState && <div>
                    <Button icon="search" color={this.state.inspecting ? "brown" : undefined} content="Inspect testid" onClick={() => this.slave.toggleInspect()} />
                    {this.state.inspecting && !this.state.inspectedElements && <Message color="brown"><MarkdownExt>{`
No element w/ \`${this.testIdProp}\` found under the mouse pointer.
Move the mouse over various elements. If nothing found, did you add cf. below? E.g.:

\`\`\`
<Button ${this.testIdProp}={testids.edit} ... />
\`\`\`

Click anywhere to end the "inspect" mode".
            `}</MarkdownExt></Message>}
                    {this.state.inspectedElements && <>
                        {this.state.justCopiedToClipboard ? <Button color="green" icon="check" content="Copied to clipboard!" /> :
                            <Dropdown text='Copy code snippet for ...' icon='copy outline' floating labeled button className='icon brown'>
                                <Dropdown.Menu>
                                    <Popup trigger={<Dropdown.Item text='Selector' onClick={() => this.copySnippet(this.getSnippet())} />} position="right center" content={this.renderSnippet(this.getSnippet())} />
                                    <Popup trigger={<Dropdown.Item text='Selector + action' onClick={() => this.copySnippet(this.getSnippet("action"))} />} position="right center" content={this.renderSnippet(this.getSnippet("action"))} />
                                    <Popup trigger={<Dropdown.Item text='Selector + verification' onClick={() => this.copySnippet(this.getSnippet("verification"))} />} position="right center" content={this.renderSnippet(this.getSnippet("verification"))} />
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        <Button color="brown" content="Clear" onClick={() => this.setState({ inspectedElements: undefined })} />
                        <Message color="brown">
                            <code>{this.testIdProp}</code>s under mouse cursor (child &gt; parent): <br />
                            {this.state.inspectedElements.map((element, i) =>
                                <Label key={i} color="brown" content={element.getAttribute(this.testIdProp)}
                                    onMouseEnter={() => this.slave.setState({ inspectedElement: element })}
                                    onMouseLeave={() => this.slave.setState({ inspectedElement: undefined })} />)}
                            <br />
                            Click anywhere to end "inspect" mode. Hover labels to focus.
                        </Message>
                    </>}
                </div>}
                {TabState.MINI_DB === tabState && <MiniDbTableSetComponent ref={this.refMiniDbTableSetComponent} />}
                {TabState.CAPTURE_UI_API_EVENTS === tabState && <CaptureUiApiEvents ref={this.refCaptureUiApiEvents} master={this} capturedUiApiCalls={this.state.capturedUiApiCalls} />}
            </Segment>
        </div>
    }

    protected onStepByStepModeChanged = (e: any, { value }: DropdownProps) => {
        const mode = value as StepByStepMode;
        this.slave.stepByStep = mode === StepByStepMode.ON;
        localStorage.setItem(LOCAL_STORAGE_STEP_BY_STEP, "" + mode);
        this.setState({ stepByStepMode: mode });
    }

    protected getTestIdExpression(element: HTMLElement) {
        let result = Utils.substringAfter(element.getAttribute(this.testIdProp)!, "_");
        const spl = result.split("_");
        if (spl.length === 1) {
            return result;
        } else {
            return `${spl[0]} + "_${spl[1]}"`;
        }
    }

    protected getSelectorExpression(inspectedElements: HTMLElement[]) {
        // I saw that if the lines have 1 tab, VS code will auto indent them. No tab => no auto indent
        let pre = "\t";
        let item = "";
        if (inspectedElements.length === 1) {
            return { pre, result: `tad.screenCapturing.getByTestId(testids.${this.getTestIdExpression(inspectedElements[0])})` };
        } else if (inspectedElements.length === 2) {
            item = "item";
        } else if (inspectedElements.length > 2) {
            item = "item1";
        }
        pre += `let ${item} = ` + `tad.screenCapturing.getByTestId(testids.${this.getTestIdExpression(inspectedElements[inspectedElements.length - 1])});\n\t`;

        for (let i = inspectedElements.length - 2; i >= 1; i--) {
            const prevItem = item;
            item = "item" + (inspectedElements.length - i);
            pre += `let ${item} = ` + `tad.withinCapturing(${prevItem}).getByTestId(testids.${this.getTestIdExpression(inspectedElements[i])});\n\t`
        }

        return { pre, result: `tad.withinCapturing(${item}).getByTestId(testids.${this.getTestIdExpression(inspectedElements[0])})` }
    }

    protected getSnippet(type?: "action" | "verification") {
        const r = this.getSelectorExpression(this.state.inspectedElements!);
        let lastLine;
        if (type === "action") {
            lastLine = `await tad.userEventWaitable.click(${r.result});`
        } else if (type === "verification") {
            lastLine = `await tad.assertWaitable.equal(${r.result}, "???");`;
        } else {
            lastLine = r.result + ";";
        }
        return r.pre + lastLine;
    }

    protected renderSnippet(snippet: string) {
        return <MarkdownExt>{"```\n" + snippet + "\n```"}</MarkdownExt>;
    }

    protected async copySnippet(snippet: string) {
        await navigator.clipboard.writeText(snippet);
        this.setState({ justCopiedToClipboard: true });
        await FoundationUtils.setTimeoutAsync(1000);
        this.setState({ justCopiedToClipboard: false });
    }

    protected renderControlPanel() {
        let nextStepSuffix = "";
        if (this.state.autoPressNextAt) {
            nextStepSuffix = " (" + Math.round((this.state.autoPressNextAt - Utils.now().getTime()) / 1000) + ")";
        }
        const dropdownOptions = [
            { key: StepByStepMode.OFF, value: StepByStepMode.OFF, text: "OFF" },
            { key: StepByStepMode.ON, value: StepByStepMode.ON, text: "ON" },
            { key: StepByStepMode.CONTROLLED_BY_PROGRAM, value: StepByStepMode.CONTROLLED_BY_PROGRAM, text: "Controlled by program" },
        ];
        return <div>
            <Sidebar className="TestsAreDemo_sidebar" as={Segment} animation="overlay" width="very wide"
                visible={this.state.sidebar} onHide={() => this.setState({ sidebar: false })}>
                <Grid><Grid.Row columns={1}><Grid.Column>
                    {/* <Header as='h3'>New Content Awaits</Header> */}
                    <Button content={`Layout: ${this.state.horizontalLayout ? "horizontal" : "vertical"}`} onClick={() => {
                        this.setState({ horizontalLayout: !this.state.horizontalLayout });
                        this.resetSizes();
                        setTimeout(() => localStorage.setItem(LOCAL_STORAGE_HORIZONTAL_LAYOUT, "" + this.state.horizontalLayout));
                    }} />
                    <Button icon="redo" content="Reset sizes" onClick={this.resetSizes} />
                    <Divider className="TestsAreDemo_divider" />
                    <Checkbox checked={this.state.autoPressNextEnabled} label="Auto press 'Next step' with timer" onChange={() => {
                        if (this.state.autoPressNextEnabled) {
                            this.disableAutoPressTimerIfEnabled();
                        } else {
                            this.enableAutoPressTimer();
                        }
                        localStorage.setItem(LOCAL_STORAGE_AUTO_PRESS_NEXT, "" + !this.state.autoPressNextEnabled);
                        this.setState({ autoPressNextEnabled: !this.state.autoPressNextEnabled });
                    }} />
                    <br />
                    <Checkbox checked={this.getAutoRun()} label="Auto run after page load" onChange={() => {
                        localStorage.setItem(LOCAL_STORAGE_AUTO_RUN, this.getAutoRun() ? "false" : "true");
                        this.forceUpdate();
                    }} />
                    <br />
                    <Checkbox checked={this.state.endUser} label="'Demo for end user' mode" onChange={() => {
                        localStorage.setItem(LOCAL_STORAGE_END_USER, "" + !this.state.endUser);
                        this.setState({ endUser: !this.state.endUser });
                    }} />
                    <br />
                </Grid.Column></Grid.Row></Grid>
            </Sidebar>
            {this.state.sidebar && <Modal.Dimmer />}

            <Button icon="bars" onClick={() => this.setState({ sidebar: true })} />

            <Dropdown
                className='button icon'
                floating
                options={dropdownOptions}
                trigger={<>Step by step mode: {dropdownOptions[this.state.stepByStepMode].text}&nbsp;&nbsp;</>} value={this.state.stepByStepMode}
                onChange={this.onStepByStepModeChanged}
            />

            <Button data-testid={testids.run} icon="play" content={"Run: " + getTestToRunCountLabel(this.state.testsToRun)} disabled={this.state.running} onClick={() => this.run()} />
            {this.state.finishWaitingCallback && <Button data-testid={testids.nextStep} icon="step forward" content={"Next step" + nextStepSuffix} onClick={() => this.nextStep()} />}
            {this.state.finishWaitingCallback && <Button icon="forward" content="Run normally" onClick={() => {
                // we set this, and we'll re-read from local storage at the end
                this.slave.stepByStep = false;
                this.nextStep();
            }} />}
        </div>
    }

    protected onModifSize1 = (newSize: number) => {
        this.setState({ size1: newSize });
        localStorage.setItem(LOCAL_STORAGE_SIZE1, "" + newSize);
    }

    protected onModifSize2 = (newSize: number) => {
        this.setState({ size2: newSize });
        localStorage.setItem(LOCAL_STORAGE_SIZE2, "" + newSize);
    }

    protected resetSizes = () => {
        localStorage.removeItem(LOCAL_STORAGE_SIZE1);
        localStorage.removeItem(LOCAL_STORAGE_SIZE2);
        this.setState({ size1: undefined, size2: undefined });
    }

    protected onModifMenuState = (menuState: MenuState) => {
        this.setState({ menuState });
        localStorage.setItem(LOCAL_STORAGE_MENU_STATE, "" + menuState);
    }

    render() {
        if (this.props.puppeteer) {
            return this.renderTad();
        }
        const { menuState } = this.state;
        return (<>
            <Menu style={{ margin: "0" }}>
                <Menu.Item active={MenuState.FB === menuState} onClick={() => this.onModifMenuState(MenuState.FB)}>Featurebook (for live tests)</Menu.Item>
                <Menu.Item active={MenuState.TAD === menuState} onClick={() => this.onModifMenuState(MenuState.TAD)}>Run tests</Menu.Item>
                <Menu.Item active={MenuState.FB_RECORDED === menuState} onClick={() => this.onModifMenuState(MenuState.FB_RECORDED)}>Featurebook (for recorded tests)</Menu.Item>
                <Menu.Item active={MenuState.FB_SERVER === menuState} onClick={() => this.onModifMenuState(MenuState.FB_SERVER)}>Featurebook server (new, but not yet checkable/runnable)</Menu.Item>
                <Menu.Item active={MenuState.FB_SERVER_OLD === menuState} onClick={() => this.onModifMenuState(MenuState.FB_SERVER_OLD)}>Featurebook server (old)</Menu.Item>
                <Menu.Item active={MenuState.DOC === menuState} onClick={() => this.onModifMenuState(MenuState.DOC)}>Doc from tests (deprecated)</Menu.Item>
                <Menu.Item active={MenuState.SCREENSHOTS === menuState} onClick={() => this.onModifMenuState(MenuState.SCREENSHOTS)}>Screenshots from tests (deprecated)</Menu.Item>
            </Menu>
            <Segment className="TestsAreDemo_wrapperSegment" basic>
                <div className="TestsAreDemo_wrapperSegment" style={{ display: MenuState.TAD === menuState ? "flex" : "none" }}>{this.renderTad()}</div>
                {MenuState.FB === menuState && <Featurebook master={this} running={this.state.running}
                    testClassDescriptors={this.state.testClassDescriptors} testsToRun={this.state.testsToRun} />}
                {MenuState.FB_RECORDED === menuState && <FeaturebookUiRecorded master={this} showLeftPane recordedTestsUrl={RECORDED_TESTS_DIR + RECORDED_TESTS_INDEX} />}
                {MenuState.FB_SERVER === menuState && <FeaturebookServer serverUrlPrefix={this.props.serverUrlPrefix} />}
                {MenuState.FB_SERVER_OLD === menuState && <FeatureBookServer serverUrlPrefix={this.props.serverUrlPrefix} />}
                {MenuState.DOC === menuState && <DocFromTests />}
                {MenuState.SCREENSHOTS === menuState && <ScreenshotsFromTests master={this} />}
            </Segment>
        </>);
    }

    renderTad() {
        if (this.props.puppeteer) {
            return this.renderIFramePane();
        }
        return this.state.horizontalLayout
            ? <SplitPaneExt size={this.state.size1 || "60%"} split="horizontal" onDragFinished={this.onModifSize1} onResizerDoubleClick={this.resetSizes}>
                {this.renderIFramePane()}
                {!this._slave ? <p>TestsAreDemoSlave not yet loaded</p> : <SplitPaneExt split="vertical" size={this.state.size2 || "15%"} onDragFinished={this.onModifSize2} onResizerDoubleClick={this.resetSizes}>
                    {this.renderControlPanel()}
                    {this.renderTabs()}
                </SplitPaneExt>}
            </SplitPaneExt>
            : <SplitPaneExt size={this.state.size1 || "30%"} split="vertical" onDragFinished={this.onModifSize1} onResizerDoubleClick={this.resetSizes}>
                {!this._slave ? <p>TestsAreDemoSlave not yet loaded</p> : <>
                    {/* 
                        For the moment we don't need a second split in this case, because we have hidden the additional commands/UI in the drawer.
                        But let's keep this code for a while. Who knows what features we'll add in the future, which will be needed in the main UI (and not hidden in the drawer).
                     */}
                    {/* <SplitPaneExt split="horizontal" size={this.state.size2 || "50%"} onDragFinished={this.onModifSize2} onResizerDoubleClick={this.resetSizes}> */}
                    <div className="flex-container wh100">
                        {this.renderControlPanel()}
                        <Divider className="TestsAreDemo_divider" />
                        {this.renderTabs()}
                    </div>
                    {/* </SplitPaneExt> */}
                </>}

                {this.renderIFramePane()}
            </SplitPaneExt>
    }

}