import { Component } from "react";
import { Divider, Segment, SegmentGroup } from "semantic-ui-react";
import { MarkdownExt } from "../MarkdownExt";
import { TestClassDescriptor } from "../TestsAreDemoMaster";
import { FeaturebookCommonProps } from "./Featurebook";
import { TestFunctionComponent } from "./TestFunctionComponent";
import { TestsClassNameRenderer } from "./TestClassNameRenderer";

export interface TestClassComponentProps extends FeaturebookCommonProps {
    testClassDescriptor?: TestClassDescriptor;
    recordedTestsUrl?: string;
    includeCredentialsInJsonRequest?: boolean;
}

interface State {
    expanded?: boolean;
    showThumbnails?: boolean;
}

export class TestClassComponent extends Component<TestClassComponentProps, State> {

    state: State = {};

    render() {
        let { master, testClassDescriptor, testsToRun, ...props } = this.props;

        const title = testClassDescriptor?.name || "There are no tests";
        return <div className="TestClassComponent">
            <div className="TestClassComponent_header">
                <h1><TestsClassNameRenderer name={title} /></h1>
            </div>
            <Divider className="TestClassComponent_divider" />
            {testClassDescriptor?.comments && <SegmentGroup>
                {testClassDescriptor?.comments?.map((comment, i) => <Segment key={i}>
                    <MarkdownExt>{comment}</MarkdownExt>
                </Segment>)}
            </SegmentGroup>}
            {testClassDescriptor?.functions.map((functionDescriptor, i) => <TestFunctionComponent key={i} master={master} includeCredentialsInJsonRequest={props.includeCredentialsInJsonRequest}
                testClassDescriptor={testClassDescriptor!} testsToRun={testsToRun} testFunctionDescriptor={functionDescriptor}
                recordedTestsUrl={props.recordedTestsUrl}
                expanded={this.state.expanded} onToggleExpanded={() => this.setState({ expanded: !this.state.expanded })}
                showThumbnails={this.state.showThumbnails} onToggleShowThumbnails={() => this.setState({ showThumbnails: !this.state.showThumbnails })} />)}
        </div>
    }
}