import { ModalExt, ModalExtProps } from "@famiprog-foundation/scriptable-ui";
import { Modal } from "semantic-ui-react";
import { TestClassDescriptor, TestsAreDemoMaster } from "./TestsAreDemoMaster";
import { BoundingRect } from "react-measure";
import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export enum ModalForTestsOpenMode { CLOSED, POPUP_FOR_TEST, PSEUDO_INCLUDED_FOR_COMPONENT_DEMO };

interface Props {
    master: TestsAreDemoMaster;
    testClassDescriptor?: TestClassDescriptor;
    bounds?: BoundingRect;
    openMode: ModalForTestsOpenMode
    children: ReactNode;
}

/**
 * Is always rendered in the DOM, and the show/hide is w/ `display: none`. This is because the life of the
 * app starts when the slave / iframe loads (i.e. it reads test descriptors). So this needs to happen at the
 * very beginning of the app.
 */
export function ModalForTests(props: Props) {
    const ref = useRef<ModalExt>(null);
    // I'm not using a normal ref; I'm using a ref callback, and store the result in the state.
    // This is because I want this component to redraw when the div in the portal appears. Because
    // only after that moment, I want the ModalExt to be rendered. So we are waiting for the div
    // in the portal to appear. W/o this, an initial render of the ModalExt in body would happen. 
    // And then an unmmount/mount in the div, when it appears.
    const [div, setDiv] = useState<HTMLDivElement | null>(null);
    const { bounds } = props;
    useEffect(() => {
        if (!bounds || !ref.current?.modalElement) {
            return;
        }
        const { modalElement } = ref.current;

        modalElement.style.top = bounds.top + "px";
        modalElement.style.left = bounds.left + "px";
        modalElement.style.width = bounds.width + "px";
        modalElement.style.height = bounds.height + "px";
    }, [bounds]);
    const modalProps: Partial<ModalExtProps> = {
        // reusing this css class
        className: "RecordedTestModalRoot",
    }
    if (props.openMode === ModalForTestsOpenMode.POPUP_FOR_TEST) {
        modalProps.closeIcon = true;
        modalProps.onClose = () => {
            if (props.master.state.running) {
                alert("Cannot close while tests are still running");
                return;
            }
            props.master.setModalOpenMode(ModalForTestsOpenMode.CLOSED);

        }
        modalProps.size = "fullscreen";
    } else if (props.openMode === ModalForTestsOpenMode.PSEUDO_INCLUDED_FOR_COMPONENT_DEMO) {
        modalProps.transparentDimmer = true;
        modalProps.className += " ModalForTests_pseudoIncludedForComponentDemo"
    }
    return <>
        {/* 
            The modal is rendered in a new `<div>`. Not in `<body>`, because the dimmer puts as inline style: `display: flex !important`.
            That means I cannot override the `display` property. So I create a parent, and manipulate its `display`.
         */}
        {createPortal(<div ref={value => setDiv(value)} style={{ display: props.openMode !== ModalForTestsOpenMode.CLOSED ? "block" : "none" }} />, document.body)}
        {div && <ModalExt {...modalProps} open ref={ref} mountNode={div} closeOnDimmerClick={false} closeOnEscape={false}>
            {/* a conditional rendering of the header would remount/reload de iframe; hence I'm hiding it via CSS */}
            <Modal.Header>Run tests</Modal.Header>
            <Modal.Content style={{ height: "100%" }}>{props.children}</Modal.Content>
        </ModalExt>}
    </>
}