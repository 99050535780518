import { FilterOperators } from "@crispico/foundation-gwt-js";
import { ScriptableUiHighlightWrapper, WithHW } from "@famiprog-foundation/scriptable-ui";
import gql from "graphql-tag";
import _ from "lodash";
import React from "react";
import { ActionMeta, components, ControlProps, InputActionMeta, NamedProps, OptionProps, SingleValueProps } from "react-select";
import { SortEnd } from "react-sortable-hoc";
import { Icon } from "semantic-ui-react";
import { Sort } from "../../apollo-gen-foundation/Sort";
import { apolloClient } from "../../apolloClient";
import { Filter } from "../../components/CustomQuery/Filter";
import { SelectExt, SelectExtOption } from "../../components/selectExt/SelectExt";
import { Utils } from "../../utils/Utils";
import { FieldEditorProps } from "../fieldRenderersEditors";
import { FindByStringParams } from "../FindByStringParams";
import { FieldEditor, FieldEditorNotUsableStandAloneProps, ScriptableUiFieldEditor } from "./FieldEditor";
import { EntityDescriptor } from "../EntityDescriptor";

export interface AssociationEditorProps {
    innerEntityDescriptor?: EntityDescriptor;
    selectedValue?: any,
    sorts?: Sort[]
    filter?: Filter,
    onChange?: (entity: any) => void,
    additionalSearchFields?: string[],
    queryLimit?: number,
}

export type SelectedValue = undefined | SelectExtOption | SelectExtOption[];

export type AssociationExtraProps = NamedProps;

export type AssociationFieldEditorProps = FieldEditorNotUsableStandAloneProps & AssociationEditorProps & AssociationExtraProps;

export type AssociationFieldEditorState = {
    entities: any[],
    selectedOption: any,
    // This value is used to prevent SelectExt at rerender to make an unnecessary request (the query from onMenuOpen method)
    isMenuOpened: boolean,
    showArchived: boolean
};

export const QUERY_LIMIT_NUMBER = 10;

export class AssociationFieldEditor<P, S extends AssociationFieldEditorState = AssociationFieldEditorState> extends FieldEditor<any, P & AssociationFieldEditorProps, S> {
    protected selectExtRef = React.createRef<any>();

    static defaultProps = {
        queryLimit: QUERY_LIMIT_NUMBER,
        formikProps: undefined
    };

    constructor(props: P & AssociationFieldEditorProps) {
        super(props);

        this.state = {
            ...this.state,
            entities: [],
            selectedOption: this.props.formikProps ? this.getSelectedOption() : null,
            isMenuOpened: false,
            showArchived: false
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onMenuOpen = this.onMenuOpen.bind(this);
        this.updateCurrentOption = this.updateCurrentOption.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.onMenuClose = this.onMenuClose.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.renderExtraButtons = this.renderExtraButtons.bind(this);
        this.customRendererForOptions = this.customRendererForOptions.bind(this);
        this.customRendererForSelectedValue = this.customRendererForSelectedValue.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<P & AssociationFieldEditorProps>, prevState: Readonly<AssociationFieldEditorState>, snapshot?: any): void {
        const props = this.props as AssociationFieldEditorProps;
        if (!_.isEqual(prevProps.innerEntityDescriptor, this.props.innerEntityDescriptor)) {
            this.setState({ entities: [], selectedOption: [] });
            if (this.props.onChange) {
                this.props.onChange([]);
            }
            this.changeSelectedValue([]);
        }
        // We should make an extra query if the button for archived filter changed his state because we can't
        // stop propagation for click event and menu is also opened.
        if (!_.isEqual(prevState.showArchived, this.state.showArchived)) {
            this.performQuery();
        }
        if (!_.isEqual(prevProps.formikProps?.values[this.props.fieldDescriptor.name], props.formikProps?.values[this.props.fieldDescriptor.name])) {
            this.setState({ selectedOption: this.getSelectedOption() })
        }
    }

    protected createFindByStringParams(searchQuery: string): FindByStringParams {
        let p = FindByStringParams.create().string(searchQuery).pageSize(QUERY_LIMIT_NUMBER);
        p.params.sorts = this.props.sorts;
        let filter: Filter | undefined = this.props.filter;
        if (this.state.showArchived === false && this.props.innerEntityDescriptor?.hasArchivedField()) {
            let filters = [Filter.create("archived", FilterOperators.forBoolean.equals, "false")];
            if (this.props.filter) {
                filters.push(this.props.filter);
            }
            let { enabled, label, ...composedFilter } = Filter.createComposedForClient(FilterOperators.forComposedFilter.and, filters);
            filter = composedFilter;
        }
        p.params.filter = filter;
        return p;
    }

    protected createQuery(operationName?: string) {
        let name: string = operationName ? operationName : `${_.lowerFirst(this.props.innerEntityDescriptor!.name)}Service_findByString`;

        let fields: string[] = this.props.innerEntityDescriptor!.miniFields.concat(this.props.additionalSearchFields ? this.props.additionalSearchFields : []);

        let archived = this.props.innerEntityDescriptor?.hasArchivedField() ? "archived" : "";

        let query = gql(`query q($params: FindByStringParamsInput) { 
            ${name}(params: $params) {
                ${this.props.innerEntityDescriptor!.idFields.join(" ")} ${this.props.innerEntityDescriptor!.getGraphQlFieldsToRequest(fields)} ${archived}
            }
        }`);

        return {
            name: name,
            query: query
        }
    }

    protected async performQuery(searchQuery?: string, operationName?: string) {
        // In storybook, we have cases (e.g. BlocklyEditorTab) where we don't receive an innerEntityDescriptor
        if (!this.props.innerEntityDescriptor) {
            return;
        }

        let { name, query } = this.createQuery(operationName);

        let result = (await apolloClient.query({
            query: query,
            variables: this.createFindByStringParams(searchQuery ? searchQuery : ""),
            context: { showSpinner: false }
        })).data[name];

        this.setState({ entities: result });
    }

    protected onInputChange(value: string, action: InputActionMeta) {
        // We shouldn't make a query if value contains only spaces because server can't validate
        // a filter with this kind of value
        if (value.trim().length !== 0 || value === "") {
            this.performQuery(value);
        }
    }

    // This method will be called if the editor is inside a form.
    protected getSelectedOption(): any {
        const props = this.props;
        return props.formikProps &&
            Utils.navigate(props.formikProps.values, props.fieldDescriptor.name, false, ".");
    }

    protected getLabel(entity: any): string {
        return this.props.innerEntityDescriptor!.toMiniString(entity) + (this.props.additionalSearchFields ? " " + this.props.additionalSearchFields?.map((field: string) => entity[field]).join(" ") : "");
    }

    protected getSelectedValue(): SelectedValue | null {
        let selectedValue: SelectedValue | null = null;
        if (this.state.selectedOption) {
            if (Array.isArray(this.state.selectedOption) && this.state.selectedOption.length > 0) {
                selectedValue = this.state.selectedOption.map((value: any) => ({
                    label: this.getLabel(value),
                    value: this.props.innerEntityDescriptor!.idFields.map((idField: string) => value[idField]).join(" "),
                    entity: value
                }));
            }
            else if (!Array.isArray(this.state.selectedOption)) {
                selectedValue = {
                    label: this.getLabel(this.state.selectedOption),
                    value: this.props.innerEntityDescriptor!.idFields.map((idField: string) => this.state.selectedOption[idField]).join(" "),
                    entity: this.state.selectedOption
                };
            }
        }
        return selectedValue;
    }

    // This method will be called if the editor is inside a form.
    // It can be also overrided by a component which extends this 
    protected changeSelectedValue(option: any, s?: WithHW<ScriptableUiFieldEditor.Main>, hw?: ScriptableUiHighlightWrapper) {
        const props = this.props;
        if (s && hw && props.formikProps) {
            s.setFieldValue(hw, option);
        } else if (props.formikProps) {
            props.formikProps.setFieldValue(props.fieldDescriptor.name, option);
        }
    }

    protected updateCurrentOption(value: any, action: ActionMeta<any>, s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        let option: any = null;
        if (value) {
            if (this.props.isMulti) {
                option = value.map((value: any) => value.entity);
            }
            else {
                option = value.entity;
            }
        }
        if (this.props.onChange) {
            this.props.onChange(option);
        }
        this.setState({ selectedOption: option });
        this.changeSelectedValue(option, s, hw);
    }

    protected onSortEnd(sort: SortEnd) {
        // nop
    }

    protected onMenuOpen() {
        if (!this.state.isMenuOpened) {
            this.performQuery();
            this.setState({
                isMenuOpened: true
            });
        }
    }

    protected onFocus(event: React.FocusEvent<HTMLElement, Element>) {
        this.performQuery();
    }

    protected onMenuClose() {
        this.setState({
            isMenuOpened: false
        });
    }

    protected checkEntityDescriptor(): boolean {
        return !this.props.innerEntityDescriptor && this.state.entities.length !== 0;
    }

    protected renderExtraButtons({ children, ...props }: ControlProps<SelectExtOption>) {
        return (<components.Control {...props}>
            {children}
            {this.props.innerEntityDescriptor?.hasArchivedField() && <Icon color={this.state.showArchived ? "green" : "red"} className="AssociationFieldEditor_archiveButton"
                name="archive" onClick={() => {
                    this.setState({ showArchived: !this.state.showArchived })
                }} />}
        </components.Control>);
    };

    protected renderLabelWithArchived(children: any, checkIsArchived: boolean) {
        return <div className="flex container AssociationFieldEditor_customOption">
            <div className="AssociationFieldEditor_optionContainer">
                {children}
            </div>
            {checkIsArchived && <Icon name="archive" />}
        </div>;
    }

    protected customRendererForOptions({ children, ...props }: OptionProps<SelectExtOption>) {
        return <components.Option {...props}>
            {this.renderLabelWithArchived(children, this.props.innerEntityDescriptor?.hasArchivedField() &&
                (this.state.selectedOption && this.getLabel(this.state.selectedOption) === children ?
                    this.state.selectedOption["archived"] :
                    this.state.entities.find((entity) => this.getLabel(entity) === children) ?
                        this.state.entities.find((entity) => this.getLabel(entity) === children)["archived"] :
                        false
                ))}
        </components.Option>;
    }

    protected customRendererForSelectedValue({ children, ...props }: SingleValueProps<SelectExtOption>) {
        return <components.SingleValue {...props}>
            {this.renderLabelWithArchived(children, this.props.innerEntityDescriptor?.hasArchivedField() &&
                this.state.selectedOption && this.state.selectedOption["archived"])}
        </components.SingleValue>;
    }

    protected getSelectExtProps() {
        // We have some cases where innerEntityDescriptor is changing to undefined and
        // also we have some options in state. We must ignore them because program will crash
        // at instructions like: this.props.innerEntityDescriptor!.idFields
        let options: SelectExtOption[] = this.checkEntityDescriptor() ? [] : this.state.entities!.map(entity => ({
            label: this.getLabel(entity),
            value: this.props.innerEntityDescriptor!.idFields.map((idField: string) => entity[idField]).join(" "),
            entity: entity
        }));

        // Also, we must ignore selectedValues that come from props
        let selectedValue: SelectedValue | null = this.checkEntityDescriptor() ? undefined : this.props.selectedValue ? {
            label: this.getLabel(this.props.selectedValue),
            value: this.props.innerEntityDescriptor!.idFields.map((idField: string) => this.props.selectedValue![idField]).join(" "),
            entity: this.props.selectedValue
        } : this.getSelectedValue();

        if (!this.props.isMulti && selectedValue && !Array.isArray(selectedValue)) {
            let currentValueId = selectedValue.value;
            if (options.filter((value) => value.value === currentValueId).length === 0) {
                options.unshift(selectedValue);
            }
        }

        if (this.props.queryLimit !== -1) {
            if (options.length >= QUERY_LIMIT_NUMBER) {
                options.splice(QUERY_LIMIT_NUMBER, options.length - QUERY_LIMIT_NUMBER);
            }
        }

        if (this.props.isMulti && !selectedValue) {
            selectedValue = []
        }

        return { options, selectedValue };
    }

    protected renderEditorComponent(s: WithHW<ScriptableUiFieldEditor.Main>, hw: ScriptableUiHighlightWrapper) {
        // props is used to override default props for SelectExt
        const { options, selectedValue, ...props } = this.getSelectExtProps();

        return <SelectExt autoFocus={this.props.autoFocus} ref={this.selectExtRef} options={options} placeholder={this.props.placeholder} value={selectedValue} customRendererForOptions={this.customRendererForOptions}
            isMulti={this.props.isMulti ? this.props.isMulti : false} closeMenuOnSelect={this.props.isMulti ? false : true} customRendererForSelectedValue={this.customRendererForSelectedValue}
            onFocus={this.onFocus} controlShouldRenderValue={this.props.controlShouldRenderValue} onMenuOpen={this.onMenuOpen} renderExtraButtons={this.renderExtraButtons}
            onChange={(value, action) => this.updateCurrentOption(value, action, s, hw)} onSortEnd={this.onSortEnd} onMenuClose={this.onMenuClose} onInputChange={this.onInputChange}
            isDisabled={this.props.isDisabled !== undefined ? this.props.isDisabled : !this.props.fieldDescriptor?.enabled} isClearable={this.props.isClearable !== undefined ? this.props.isClearable : true}
            header={this.props.queryLimit !== -1 && options.length >= QUERY_LIMIT_NUMBER ? _msg("AssociationFieldEditor.header", options.length) : undefined} {...props} />;
    }
}