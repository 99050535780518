import { Button } from "semantic-ui-react";
import { TestClassDescriptor, TestFunctionDescriptor } from "../TestsAreDemoMaster";
import { Featurebook, FeaturebookUiProps, FeaturebookUiState } from "./Featurebook";
import { TestClassComponentProps } from "./TestClassComponent";
import { fetchJsonCredentials, fetchJsonSafe, fetchSafe } from "./functions";

interface FeaturebookServerProps extends FeaturebookUiProps {
    serverUrlPrefix: string;
}

interface FeaturebookServerState extends FeaturebookUiState {
    testClassDescriptors?: TestClassDescriptor[];
}

export class FeaturebookServer extends Featurebook<FeaturebookServerProps, FeaturebookServerState> {

    async componentDidMount() {
        const testClassDescriptors: TestClassDescriptor[] = await fetchJsonSafe(this.props.serverUrlPrefix + "testGoodiesController/getTestClassDescriptors");
        this.setState({ testClassDescriptors });
    }

    protected getTestClassDescriptors(): TestClassDescriptor[] | undefined {
        return this.state.testClassDescriptors;
    }

    protected getAdditionalPropsForTestClassComponent(): TestClassComponentProps | undefined {
        return {
            recordedTestsUrl: "none"
        };
    }

    protected async setSelectedDescriptor(selectedDescriptor: TestClassDescriptor) {
        const functions: TestFunctionDescriptor[] = await fetchJsonSafe(this.props.serverUrlPrefix + "testGoodiesController/getMethodsWithComments?className=" + selectedDescriptor.name);
        super.setSelectedDescriptor({ ...selectedDescriptor, functions });
    }

    protected renderAdditionalsInHeader() {
        return <><Button content="Save test descriptors" onClick={async e => {
            await fetchSafe(this.props.serverUrlPrefix + "testGoodiesController/saveTestClassDescriptors");
            alert("Done!");
        }}/></>
    }
}